<template>
  <ul class="menu-nav">
    <li v-for="link in links" :key="link.id" class="menu-item" :class="{'menu-item-open': link.isCurrent,}">
      <span v-if="link.action?.route" @click="linkClick(link.action?.route, link.action?.routeLink)" class="menu-link">
        <i class="menu-icon flaticon2-list-2"></i>
        <span class="menu-text">{{link.title}}</span>
      </span>
      <template v-if="link.items">
        <span class="menu-link menu-toggle">
          <i class="menu-icon flaticon2-list-2"></i>
          <span class="menu-text">{{link.title}}</span>
          <i class="menu-arrow"></i>
        </span>
        <div class="menu-submenu">
          <ul class="menu-subnav">
            <li v-for="sublink in link.items" :key="sublink.id" class="menu-item" :class="{'menu-item-open': sublink.isCurrent,}">
              <span @click="linkClick(sublink.action?.route, sublink.action?.routeLink)" class="menu-link">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{sublink.title}}</span>
              </span>
            </li>
          </ul>
        </div>
      </template>
    </li>
  </ul>
</template>

<script>
//import {linksTestData} from './menuTestData';
import { GET_MENU, CHECK_ROUTE } from '@/core/services/store/menu.module';
export default {
  name: 'KTMenu',
  data() {
    return {
      //linksData: (typeof linksTestData !== 'undefined') ? linksTestData : [],
      linksData: [],
    }
  },
  async mounted() {
    let res = await this.$store.dispatch(GET_MENU);
    this.linksData = res?.data?.data;
  },
  computed: {
    links() {
      let res = [];
      this.linksData.forEach((item) => {
        let obj = {};

        let url = item.action?.route;
        if(url) {
          obj = item;
          obj.action.routeLink = this.cutUrl(url);

          obj.isCurrent = this.hasActiveChildren(url);
        }

        let subs = item.items;
        if(subs?.length) {
          let resSub = [];
          let hasActive = false;
          obj = {
            id: item.id,
            title: item.title,
          };

          subs.forEach(subItem => {
            let subItemObj = subItem;
            let subUrl = subItem.action?.route;
            let subIsActive = this.hasActiveChildren(subUrl);
            if(subIsActive) {
              hasActive = true;
            }
            subItemObj.isCurrent = subIsActive;

            let element = subItemObj;
            element.action.routeLink = this.cutUrl(subUrl);

            resSub.push(element);
          });

          obj.isCurrent = hasActive;
          obj.items = resSub;
        }

        if (Object.keys(obj).length !== 0)
          res.push(obj);

      })
      return res;
    }
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route['path'].indexOf(match) !== -1;
    },
    async checkRoute(link) {
      let res = await this.$store.dispatch(CHECK_ROUTE, link);
      if(res.data.error) {
        alert('Ошибка доступа');
        return false;
      }
      return true;
    },
    async linkClick(url, urlClick) {
      if(url) {
        console.log('click '+url+'->'+urlClick);
        let checkRes = await this.checkRoute(url);
        if(checkRes)
          this.$router.push(urlClick);
      }
    },
    //вырезать лишнее из url
    cutUrl(url) {
      let cut = 'adv/';
      let res = url.replace(cut, '/');
      return res;
    },
  },


};
</script>
